import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PageLead from '../components/Page/PageLead'
import PageHero from '../components/Page/PageHero'
import PageStickyBar from '../components/Page/PageStickyBar'
import PageHeading from '../components/Page/PageHeading'
import AboutClients from '../components/About/AboutClients'
import AboutProfiles from '../components/About/AboutProfiles'

const AboutPage = ({ location, data }) => {
  const page = data.prismicAbout.data
  return (
    <Layout title={page.title} headerColor="green" subscribeColor="rose">
      <SEO
        title={page.meta_title || page.title}
        description={page.meta_description || ''}
        location={location}
      />
      <PageLead lead={page.lead.html} color="grey" alt />
      <PageHero image={page.hero} text={page.hero_text} />
      <div className="relative pt-hh">
        <PageStickyBar
          headings={[page.heading_primary, page.heading_secondary]}
        />
        <div className="relative flex flex-wrap z-10">
          <div className="w-full relative md:w-1/2 lg:w-1/3 md:h-screen md:sticky md:top-2x-hh bg-peach z-10">
            <PageHeading heading={page.heading_primary} bg="white" />
            <div className="px-ogs">
              <AboutClients
                text={page.body_primary && page.body_primary}
                logos={page.logos && page.logos}
              />
            </div>
            <div className="w-full h-hh bg-white md:hidden"></div>
          </div>
          <div className="w-full relative md:w-1/2 lg:w-2/3 bg-smoke z-10">
            <PageHeading heading={page.heading_secondary} bg="white" />
            <div className="flex flex-wrap md:justify-center lg:justify-end px-ogs md:px-2x-ogs lg:pl-0 lg:pr-ogs">
              <div className="w-full lg:w-3/4 lg:pl-gs">
                <AboutProfiles
                  profiles={
                    page.display_profiles_by_region === 'True'
                      ? page.regions
                      : data.allPrismicProfiles.edges
                  }
                  regionGrouped={page.display_profiles_by_region}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

AboutPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default AboutPage

export const pageQuery = graphql`
  query {
    prismicAbout {
      data {
        title
        body_primary {
          html
        }
        heading_primary
        heading_secondary
        display_profiles_by_region
        hero {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        lead {
          html
        }
        logos {
          logo {
            url
            alt
            localFile {
              childImageSharp {
                fluid(maxWidth: 2400, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        meta_description
        meta_title
        regions {
          region {
            document {
              ... on PrismicRegions {
                id
                data {
                  title
                  profiles {
                    profile {
                      document {
                        ... on PrismicProfiles {
                          id
                          data {
                            body {
                              html
                            }
                            heading
                            subheading
                            image {
                              alt
                              url
                              localFile {
                                childImageSharp {
                                  fluid(maxWidth: 2400, quality: 90) {
                                    ...GatsbyImageSharpFluid_withWebp
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicProfiles {
      edges {
        node {
          data {
            body {
              html
            }
            heading
            subheading
            image {
              alt
              url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2400, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
